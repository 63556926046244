import "./style.css";
import { Feature, Map, Overlay, View } from "ol";
import LayerGroup from "ol/layer/Group";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorImageLayer from "ol/layer/VectorImage";
import OSM from "ol/source/OSM";
import GeoJSON from "ol/format/GeoJSON.js";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle.js";
import Text from "ol/style/Text";
import Icon from "ol/style/Icon";
import { Circle, Point, Polygon } from "ol/geom";
import Control from "ol/control/Control";
import DragRotate from "ol/interaction/DragRotate";
import PinchZoom from "ol/interaction/PinchZoom";

const container = document.getElementById("popup");
const contentSN = document.getElementById("popup-sn");
const contentType = document.getElementById("popup-type");
const contentName = document.getElementById("popup-name");
const contentSize = document.getElementById("popup-size");
const contentState = document.getElementById("popup-state");
const contentLGA = document.getElementById("popup-lga");
const contentAddress = document.getElementById("popup-address");
const contentExtraland = document.getElementById("popup-extra-land");
const contentPumpTotal = document.getElementById("popup-pumpTotal");
const contentNozzleTotal = document.getElementById("popup-NozzleTotal");
const contentTankersTotal = document.getElementById("popup-TankersTotal");
const StationItems = document.getElementById("stationItems");
const IntersectNumber = document.getElementById("popup-intersectNumber");
const MyChart = document.getElementById("myChart");
const AllTable = document.getElementById("near-table");
const nearHeading1 = document.getElementById("near-heading1");
const nearHeading2 = document.getElementById("near-heading2");
const nearHeading3 = document.getElementById("near-heading3");
const nearValue1 = document.getElementById("near-value1");
const nearValue2 = document.getElementById("near-value2");
const closer = document.getElementById("popup-closer");

/**
 * Create an overlay to anchor the popup to the map.
 */
const overlay = new Overlay({
  element: container,
  autoPan: {
    animation: {
      duration: 250,
    },
  },
});

closer.onclick = function () {
  overlay.setPosition(undefined);
  closer.blur();
  return false;
};

const map = new Map({
  target: "map",
  overlays: [overlay],
  view: new View({
    center: [863698.3136129358, 1037160.8248312974],
    zoom: 6.5,
    maxZoom: 20,
    minZoom: 5,
  }),
});

var legendControl = new Control({
  element: document.getElementById("legend-control"),
});

map.addControl(legendControl);

// Create the DragRotate and PinchZoom interactions and add them to the map
const dragRotate = new DragRotate();
const pinchZoom = new PinchZoom();
map.addInteraction(dragRotate);
map.addInteraction(pinchZoom);

//layer groups
const openstreetMap = new TileLayer({
  source: new OSM(),
  title: "OSMMap",
  visible: true,
  attribution: "MapX",
});

map.addLayer(openstreetMap);

var defaultExtent = [
  -112701.66807717911, 328416.9877889885, 1913790.5745750917,
  1754908.5860481358,
]; // Define your desired extent

function zoomToDefaultExtent() {
  map.getView().fit(defaultExtent, { duration: 2000 }); // Fit the view to the default extent
}

const fullExtentButton = document.getElementById("fullExtent");
fullExtentButton.addEventListener("click", zoomToDefaultExtent);
//clear popup on zoomout

// map.getView().on("change:resolution", function () {
//   const currentResolution = map.getView().getResolution();
//   const previousResolution = this.previousResolution || currentResolution;

//   if (currentResolution < previousResolution) {
//     // Zoomed in, do nothing
//   } else if (currentResolution > previousResolution) {
//     // Zoomed out, clear the popup
//     overlay.setPosition(undefined);
//   }

//   this.previousResolution = currentResolution;
// });

const mysataSource = new VectorSource({
  url: "./data/vector/vectordata.geojson",
  format: new GeoJSON(),
});

//vector layers
const myVectorLayerGeojson = new VectorImageLayer({
  source: mysataSource,
  crossOrigin: "",
  visible: true,
  title: "VectorData",
  style: function (feature, resolution) {
    const maxResolution = 10;

    const type = feature.get("Property_T");
    const extraLand = feature.get("Extra_Land");

    if (type === "Station" && resolution < maxResolution && extraLand !== "") {
      // Style function to display labels from the "nameslabel" property
      const stationName = feature.get("STATIONS_N").substring(10); // Trims the name from the 11th character to the end
      return new Style({
        image: new Icon({
          src: "./data/Fuel_icon.png",
          imgSize: [28, 28],
          declutterMode: "declutter",
        }),
        text: new Text({
          text: stationName, // Use the trimmed stationName as the label text
          font: "bold 10px Arial",
          fill: new Fill({ color: "black" }),
          stroke: new Stroke({ color: "white", width: 2 }),
          offsetX: 0,
          offsetY: -15,
          textAlign: "center",
          overflow: true,
          maxAngle: Math.PI / 4, // Maximum angle (in radians) between two labels before hiding one
          padding: [5, 5, 5, 5],
          maxOverlap: 10, // Maximum allowed overlap in pixels
          textBaseline: "bottom",
          backgroundFill: new Fill({
            color: "rgba(255, 255, 255, 0.5)",
          }),
          maxWidth: 10,
        }),
        declutter: true,
      });
    } else if (type === "Station" && resolution > maxResolution) {
      return new Style({
        image: new Icon({
          src: "./data/Fuel_icon.png",
          imgSize: [28, 28],
          declutterMode: "declutter",
        }),
        declutter: true,
      });
    } else if (type === "Land" && resolution < maxResolution) {
      const landName = feature.get("STATIONS_N").substring(10); // Trims the name from the 11th character to the end
      return new Style({
        image: new Icon({
          src: "./data/land.jpeg",
          imgSize: [25, 25],
          declutterMode: "declutter",
        }),
        text: new Text({
          text: landName, // Use the trimmed landName as the label text
          font: "bold 10px Arial",
          fill: new Fill({ color: "blue" }),
          stroke: new Stroke({ color: "white", width: 2 }),
          offsetX: 0,
          offsetY: -15,
          textAlign: "center",
          overflow: true,
          maxAngle: Math.PI / 4, // Maximum angle (in radians) between two labels before hiding one
          padding: [5, 5, 5, 5],
          maxOverlap: 10, // Maximum allowed overlap in pixels
          textBaseline: "bottom",
          backgroundFill: new Fill({
            color: "rgba(255, 255, 255, 0.5)",
          }),
          maxWidth: 10,
        }),
        declutter: true,
      });
    } else if (type === "Land" && resolution > maxResolution) {
      return new Style({
        image: new Icon({
          src: "./data/land.jpeg",
          imgSize: [25, 25],
          declutterMode: "declutter",
        }),
        declutter: true,
      });
    } else {
      // return new Style({
      //   image: new Icon({
      //     src: './data/land.jpeg',
      //     imgSize: [26, 26],
      //     declutterMode: 'declutter',
      //   }),
      // });
    }
  },
  // declutter: true,
});

map.addLayer(myVectorLayerGeojson);

//layer switcher

map.on("pointermove", function (e) {
  const hit = map.hasFeatureAtPixel(e.pixel);
  map.getTargetElement().style.cursor = hit ? "pointer" : "";
});

const SelectedStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({ color: "blue" }),
    stroke: new Stroke({ color: "white", width: 2 }),
  }),
});

const bufferStyle = new Style({
  fill: new Fill({
    color: "rgba(245, 167, 66, 0.3)", // Fill color with transparency
  }),
  stroke: new Stroke({
    color: "red", // Stroke color
    width: 2, // Stroke width
  }),
});

const selectedFeatureSource = new VectorSource();
const selectedLayer = new VectorLayer({
  source: selectedFeatureSource,
});
map.addLayer(selectedLayer);

const vectorSource1 = new VectorSource();
const vectorLayer1 = new VectorLayer({
  source: vectorSource1,
});
map.addLayer(vectorLayer1);

function calculateHaversineDistance(coord1, coord2) {
  const radius = 6371; // Radius of the Earth in kilometers
  const [lat1, lon1] = coord1;
  const [lat2, lon2] = coord2;

  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) *
      Math.cos(lat2 * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;

  return distance.toFixed(2); // Return the distance rounded to 2 decimal places
}

function zoomToFeature(feature) {
  // Assuming 'map' is your OpenLayers map instance
  const view = map.getView();
  const geometry = feature.getGeometry();
  view.fit(geometry, {
    padding: [20, 20, 20, 20], // Adjust padding as needed
    duration: 1000, // Animation duration in milliseconds
  });
}

map.on("singleclick", function (evt) {
  map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
    vectorSource1.clear();
    selectedFeatureSource.clear();

    const featureGeometry = feature.getGeometry();

    // Get the coordinates of the clicked feature
    const featureCoordinate = featureGeometry.getCoordinates();

    // Create a true circle geometry as the buffer
    const bufferGeometry = new Circle(featureCoordinate, 250000);
    // Close the buffer polygon
    // bufferCoordinates.push(bufferCoordinates[0]);

    // Create a polygon geometry from the buffer coordinates
    const bufferFeature = new Feature({
      geometry: bufferGeometry,
    });

    // Apply the custom style to the buffer feature
    bufferFeature.setStyle(bufferStyle);

    // Add the buffer feature to the vector source
    vectorSource1.addFeature(bufferFeature);

    // Count overlapping features within the buffer
    let overlappingFeaturesCount = 0;

    // Get the extent of the buffer feature's geometry
    const bufferExtent = bufferGeometry.getExtent();

    let table = document.getElementById("resultTable");
    let tbody = table.querySelector("tbody");

    function toSentenceCase(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    function getDistance(coord1, coord2) {
      return Math.sqrt(
        Math.pow(coord1[0] - coord2[0], 2) + Math.pow(coord1[1] - coord2[1], 2)
      );
    }

    // Clear existing rows in the table
    tbody.innerHTML = "";
    // table.style.display = "none";

    let intersectingFeaturesCount = 0; // Initialize the counter
    let intersectingFeatures = []; // Array to store intersecting features

    // Iterate through features in the vector source and check for overlaps
    mysataSource.forEachFeature(function (feature) {
      const featureGeometry = feature.getGeometry();
      const featureCoordinate2 =
        featureGeometry.getClosestPoint(featureCoordinate);
      const distance = getDistance(featureCoordinate, featureCoordinate2);

      // Check if the distance is less than the radius of the buffer
      if (distance < 250000) {
        intersectingFeaturesCount++; // Increment the counter

        let intersectName = feature.get("STATIONS_N").toUpperCase();
        let nearState = toSentenceCase(feature.get("STATE"));
        let typeF = feature.get("Property_T");
        let extralandsF = feature.get("Extra_Land");
        let neardistance = distance;
        let nearHead1 = "Asset Name";
        let nearHead2 = "State";
        let nearHead3 = "Dist.(km)";

        nearHeading1.innerHTML = nearHead1;
        nearHeading2.innerHTML = nearHead2;
        nearHeading3.innerHTML = nearHead3;

        let row = tbody.insertRow();

        let cell1 = row.insertCell(0);
        let cell2 = row.insertCell(1);
        let cell3 = row.insertCell(2);

        cell1.innerHTML = intersectName;
        cell2.innerHTML = nearState;
        cell3.innerHTML = neardistance;

        // Store feature information in the intersectingFeatures array
        intersectingFeatures.push({
          name: intersectName,
          state: nearState,
          distance: neardistance,
          type: typeF,
          extraLands: extralandsF,
        });
      }
    });

    // Sort the intersectingFeatures array by distance in ascending order
    intersectingFeatures.sort((a, b) => a.distance - b.distance);

    // Clear existing rows in the table
    tbody.innerHTML = "";

    // Initialize variables for background colors
    let stationBackgroundColor = "Orange";
    let landBackgroundColor = "blue";
    let statNoExtraland = "LightGray";
    let landNoextraland = "Tomato";

    // Iterate through the sorted intersectingFeatures array and populate the table
    for (let i = 1; i < intersectingFeatures.length; i++) {
      let featureData = intersectingFeatures[i];
      let intersectName = featureData.name;
      let nearState = featureData.state;
      let nearDistance = featureData.distance.toFixed(2);
      let featureType = featureData.type; // Assuming you have a "type" property in the featureData
      let featureextraLand = featureData.extraLands;

      // Trim intersectName to show only characters starting from the eleventh
      intersectName =
        intersectName.length > 10 ? intersectName.substring(0) : intersectName;

      let row = tbody.insertRow();
      row.classList.add("pointer-cursor"); // Add the pointer-cursor class to the table row

      let cell1 = row.insertCell(0);
      let cell2 = row.insertCell(1);
      let cell3 = row.insertCell(2);

      cell1.innerHTML = intersectName;
      cell2.innerHTML = nearState;
      cell3.innerHTML = (nearDistance / 1000).toFixed(2);

      // Set the background color for the row based on the feature type and featureextraLand
      if (featureType === "Station") {
        if (featureextraLand && featureextraLand !== "") {
          row.style.backgroundColor = stationBackgroundColor;
        } else {
          row.style.backgroundColor = statNoExtraland;
        }
      } else if (featureType === "Land") {
        if (!featureextraLand || featureextraLand === "") {
          row.style.backgroundColor = landNoextraland;
        } else {
          row.style.backgroundColor = landBackgroundColor;
        }
      }
    }

    if (intersectingFeatures.length > 0) {
      // Code for exporting to CSV
      document.getElementById("exportBuffer").addEventListener("click", function () {
        // Create the CSV content
        let csvContent = "Name,State,Distance(meters)\n"; // CSV header
    
        // Iterate through the intersectingFeatures array and add data to the CSV
        intersectingFeatures.forEach(function (featureData) {
          let row = [
            featureData.name,
            featureData.state,
            featureData.distance,
          ];
    
          csvContent += row.join(",") + "\n";
        });
    
        // Create a Blob object with the CSV content
        let blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    
        // Create a URL for the Blob
        let url = window.URL.createObjectURL(blob);
    
        // Create an anchor element for downloading the CSV
        let a = document.createElement("a");
        a.href = url;
        a.download = "intersecting_features.csv"; // Set the filename
    
        // Trigger a click event on the anchor element to start the download
        a.click();
    
        // Release the URL object
        window.URL.revokeObjectURL(url);

        alert("Downloading the CSV file");
        setTimeout(() => {
          alert("Download complete!"); // Display a second alert after a brief delay
        }, 1000); // Adjust the delay (in milliseconds) as needed
      });
    }else {
      // Show an alert message
      alert("No close features within the 250km buffer zone.");
    }
    
        


    // Get the coordinates of the clicked feature
    // Add a point feature at the clicked location within the buffer
    const pointGeometry = new Point(featureCoordinate);

    const pointFeature = new Feature({
      geometry: pointGeometry,
    });

    // Apply a style to the point feature (customize as needed)
    pointFeature.setStyle(
      new Style({
        image: new CircleStyle({
          radius: 10,
          fill: new Fill({ color: "cyan" }),
          stroke: new Stroke({ color: "white", width: 2 }),
        }),
      })
    );

    // Add the point feature to the vector source
    selectedFeatureSource.addFeature(pointFeature);

    // Get the geometry of the clicked feature
    const geometry = feature.getGeometry();

    // Calculate the extent (bounding box) of the feature's geometry
    const extent = geometry.getExtent();

    // Zoom to the extent of the clicked feature with some padding
    map.getView().fit(extent, {
      size: map.getSize(),
      padding: [10, 10, 10, 10], // Adjust padding as needed
      duration: 3000, // Animation duration in milliseconds
      maxZoom: 8, // Set the maximum zoom level to 12
    });

    let clickedFeatureName = feature.get("STATIONS_N");
    let clikedFeatureSN = feature.get("S_N");
    let clikedFeaturetype = feature.get("Property_T");
    let clickedFeatureSize = feature.get("LANDSIZE_M");
    let clickedFeatureState = feature.get("STATE");
    // let clickedFeatureAddress = feature.get("STATIONS_A");
    let clickedFeatureLGA = feature.get("L_G_A");
    let clickedFeatureExtraLand = feature.get("Extra_Land");
    let clikedFeaturePumpPms = feature.get("PUMPS_PMS");
    let clikedFeaturePumpAGO = feature.get("PUMP_AGO");
    let clikedFeaturePumpDPK = feature.get("PUMP_DPK");
    let clikedFeaturePumpTotal =
      clikedFeaturePumpPms + clikedFeaturePumpAGO + clikedFeaturePumpDPK;
    let clikedFeatureNozzlePMS = feature.get("NOZZLES_PM");
    let clikedFeatureNozzleAGO = feature.get("NOZZLES_AG");
    let clikedFeatureNozzleDPK = feature.get("NOZZLE_DPK");
    let clikedFeatureNozzleTotal =
      clikedFeatureNozzlePMS + clikedFeatureNozzleAGO + clikedFeatureNozzleDPK;
    let clikedFeatureTankersPMS = feature.get("TANKAGE_PM");
    let clikedFeatureTankersAGO = feature.get("TANKAGE_AG");
    let clikedFeatureTankersDPK = feature.get("TANKAGE_DP");
    let clikedFeatureTankersTotal =
      clikedFeatureTankersPMS +
      clikedFeatureTankersAGO +
      clikedFeatureTankersDPK;
    let clickedIntersectNumber = intersectingFeaturesCount - 1;

    overlay.setPosition(featureCoordinate);
    (contentType.innerHTML = "Asset Type: " + clikedFeaturetype),
      (contentSN.innerHTML = "ID:" + clikedFeatureSN);
    contentName.innerHTML = clickedFeatureName;
    contentSize.innerHTML = clickedFeatureSize + "m<sup>2</sup>";
    contentState.innerHTML = clickedFeatureState;
    contentLGA.innerHTML = clickedFeatureLGA;
    // contentAddress.innerHTML = clickedFeatureAddress;
    if (!clickedFeatureExtraLand) {
      contentExtraland.innerHTML = "N/A";
    } else {
      contentExtraland.innerHTML = clickedFeatureExtraLand + "m<sup>2</sup>";
    }

    // pump total
    if (clikedFeaturePumpTotal === 0) {
      contentPumpTotal.innerHTML = "";
      MyChart.style.display = "none";
      StationItems.style.display = "none";
    } else {
      StationItems.style.display = "inline-block";
      MyChart.style.display = "block";

      contentPumpTotal.innerHTML = "Pump: " + clikedFeaturePumpTotal;
    }

    //nozzle total
    if (clikedFeatureNozzleTotal === 0) {
      contentNozzleTotal.innerHTML = "";
    } else {
      contentNozzleTotal.innerHTML = "Nozzle: " + clikedFeatureNozzleTotal;
    }

    //tankers Total
    if (clikedFeatureTankersTotal === 0) {
      contentTankersTotal.innerHTML = "";
    } else {
      contentTankersTotal.innerHTML =
        "Tankage: " + clikedFeatureTankersTotal + " ltrs";
    }
    IntersectNumber.innerHTML = "Near Property(s): " + clickedIntersectNumber;
    google.charts.load("current", { packages: ["corechart"] });
    google.charts.setOnLoadCallback(drawChart);

    function drawChart() {
      // Set Data
      const data = google.visualization.arrayToDataTable([
        ["Contry", "No."],
        ["Pump PMS", clikedFeaturePumpPms],
        ["Pump AGO", clikedFeatureNozzleAGO],
        ["Pump DPK", clikedFeatureNozzleDPK],
        ["Nozzle PMS", clikedFeatureNozzlePMS],
        ["Nozzle AGO", clikedFeatureNozzleAGO],
        ["Nozzle DPK", clikedFeatureNozzleDPK],
        // ['Tankers',clikedFeatureTankersTotal],
      ]);

      // Set Options
      const options = {
        width: 250,
        height: 180,
        title: clickedFeatureName,
        hAxis: { ticks: [2, 5, 10, 15, 20] },
        legend: "none",
        fontSize: 12,
        fontName: "Arial",
        chartArea: {
          left: 70,
          top: 20,
          width: "80%",
          height: "70%",
        },
        animation: {
          duration: 1000, // in milliseconds
          easing: "out", // or 'in', 'inAndOut'
        },
        isStacked: false,
        backgroundColor: "orange",
        annotations: {
          domain: "x", // Apply labels to the x-axis (bars)
        },
        series: {
          0: { color: "tomato" }, // Color for series 0
          1: { color: "red" }, // Color for series 1
          2: { color: "green" }, // Color for series 2
        },
      };

      // Draw
      const chart = new google.visualization.BarChart(
        document.getElementById("myChart")
      );
      chart.draw(data, options);
    }
    // console.log(clickedFeatureStatus);
  });
});

// const alltable = document.getElementById("allTable");

// const allasets = [];

const searchInput = document.getElementById("searchInput");
const suggestions = document.getElementById("suggestions");

// Attach an event listener to the search input field
searchInput.addEventListener("input", function () {
  const query = this.value;
  performSearch(query);
});

// Initialize the suggestions element
suggestions.innerHTML = "";

function performSearch(query) {
  // Clear previous suggestions
  suggestions.innerHTML = "";

  // Convert query to lowercase for case-insensitive matching
  const lowerQuery = query.toLowerCase();

  // Initialize an array to store the best matches
  const bestMatches = [];

  if (query.trim() === "") {
    // Query is empty, so no need to display suggestions
    suggestions.style.display = "none";
  } else {
    // Iterate through features in the vector source
    mysataSource.forEachFeature(function (feature) {
      const name = feature.get("STATIONS_N").toLowerCase();
      const state = feature.get("STATE").toLowerCase();
      const geometry = feature.getGeometry();

      if (name.includes(lowerQuery) || state.includes(lowerQuery)) {
        bestMatches.push(feature.get("STATIONS_N"));
      }
    });

    // Sort the bestMatches array based on the similarity to the query
    bestMatches.sort((a, b) => {
      const similarityA = similarity(a.toLowerCase(), lowerQuery);
      const similarityB = similarity(b.toLowerCase(), lowerQuery);
      return similarityB - similarityA;
    });

    // Display only the best two matches
    for (let i = 0; i < Math.min(20, bestMatches.length); i++) {
      const suggestionItem = document.createElement("li");
      suggestionItem.textContent = bestMatches[i];

      // Attach an event listener to handle the selection of a suggestion
      suggestionItem.addEventListener("click", function () {
        handleSuggestionClick(bestMatches[i]);
      });

      suggestions.appendChild(suggestionItem);
    }

    suggestions.style.display = "block"; // Display the suggestion dropdown
  }
}

function handleSuggestionClick(name) {
  vectorSource1.clear();
  selectedFeatureSource.clear();
  overlay.setPosition(undefined);

  // Set the selected suggestion in the input field
  searchInput.value = name;
  // Clear suggestions
  suggestions.innerHTML = "";

  // Find the feature with the matching name
  let selectedFeature = null;
  mysataSource.forEachFeature(function (feature) {
    if (feature.get("STATIONS_N").toLowerCase() === name.toLowerCase()) {
      selectedFeature = feature;
    }
  });

  if (selectedFeature) {
    // Get the geometry of the clicked feature
    const geometry = selectedFeature.getGeometry();

    // Create an icon style with a cyan image
    const iconStyle = new Style({
      image: new Icon({
        src: "./data/Fuel_icon.png", // Provide the path to your image
        scale: 1.2, // Adjust the scale as needed
        color: "cyan", // Set the color to cyan
        offset: [0, 0], // Adjust the offset if necessary
      }),
    });

    // Apply the icon style to the selected feature
    selectedFeature.setStyle(iconStyle);

    // Calculate the extent (bounding box) of the feature's geometry
    const extent = geometry.getExtent();

    // Zoom to the extent of the clicked feature with some padding
    map.getView().fit(extent, {
      padding: [10, 10, 10, 10], // Adjust padding as needed
      duration: 3000, // Animation duration in milliseconds
      maxZoom: 14, // Set the maximum zoom level to 8 (change as needed)
    });
    const topBarHeight = (5 * window.innerHeight) / 100;
    suggestions.style.top = `${topBarHeight}px`;
  }
}

// Function to calculate the similarity between two strings
function similarity(s1, s2) {
  const longer = s1.length > s2.length ? s1 : s2;
  const shorter = s1.length > s2.length ? s2 : s1;
  const longerLength = longer.length;

  if (longerLength === 0) {
    return 1.0;
  }

  return (
    (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
  );
}

// Populate the sidebar table with feature information
const featureTable = document.getElementById("allTable");

mysataSource.on("change", function () {
  const features = mysataSource.getFeatures();

  // Sort features by 'ID'
  features.sort((a, b) => {
    const idA = a.get("S_N");
    const idB = b.get("S_N");
    return idA - idB;
  });

  featureTable.innerHTML = ""; // Clear the existing table
  featureTable.style.fontSize = "12px";
  // Create table headers
  const headerRow = featureTable.insertRow();
  headerRow.insertCell(0).innerText = "ID";
  headerRow.insertCell(1).innerText = "Name";
  headerRow.insertCell(2).innerText = "State";
  headerRow.insertCell(3).innerText = "Type";
  // headerRow.insertCell(4).innerText = "Address";

  let stationCount = 0;
  let stationWithExtraLandCount = 0;
  let landCount = 0;


  features.forEach((feature, index) => {
    const properties = feature.getProperties();
    // Trim the name field to start from the eleventh character
    const nameValue = feature.get("STATIONS_N");
    const trimmedName = nameValue.substring(0);
    const capitalizedName =
    trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);
    const row = featureTable.insertRow();
    const name = capitalizedName;

    const state = feature.get("STATE").toUpperCase();
    const id = feature.get("S_N");
    const type = feature.get("Property_T");
    const extraland = feature.get("Extra_Land");
    // const address = feature.get("STATIONS_A");

    row.insertCell(0).innerText = id;
    row.insertCell(1).innerText = name;
    row.insertCell(2).innerText = state;
    row.insertCell(3).innerText = type;
    // row.insertCell(4).innerText = address;

    // Add a click event listener to zoom to the feature location at zoom level 8
    row.addEventListener("click", () => {
      const geometry = feature.getGeometry();
      map.getView().animate({
        zoom: 14, // Set the desired zoom level (e.g., 8)
        center: geometry.getCoordinates(),
        duration: 1000, // You can adjust the duration as needed
      });
    });



    // Set row background color based on 'type' and 'extraland'
    if (type === "Station" && extraland == "") {
      row.style.backgroundColor = "Orange";
      stationCount++;
    } else if (type === "Station" && extraland !== "") {
      row.style.backgroundColor = "Tomato";
      stationWithExtraLandCount++;
    } else if (type === "Land") {
      row.style.backgroundColor = "lightgray";
      landCount++;
    }

    // Add CSS to change the cursor to a pointer on row hover
    row.style.cursor = "pointer";
  });

  // Display the counts in an element or console
  console.log(`Station Count: ${stationCount}`);
  console.log(`Station with Extra Land Count: ${stationWithExtraLandCount}`);
  console.log(`Land Count: ${landCount}`);

  const stationCounts = document.getElementById("stationwithextraland");
  const stationNoCounts = document.getElementById("stationNohextraland");
  const landcounts = document.getElementById("landedproperty");
  stationCounts.innerHTML = "Extra Land:<br> "+stationWithExtraLandCount;
  stationNoCounts.innerHTML = "No Space:<br> "+stationCount;
  landcounts.innerHTML = "Land Properties: <br>"+landCount;

});

// Find the input and select elements for filtering
const tableFilterInput = document.getElementById("tableFilter");
const filterCriterionSelect = document.getElementById("filterCriterion");

// Add an input event listener to the filter input field
tableFilterInput.addEventListener("input", function () {
  const filterValue = tableFilterInput.value.toLowerCase();
  const selectedCriterion = filterCriterionSelect.value;

  // Get all rows in the table except for the header
  const rows = featureTable.rows;

  for (let i = 1; i < rows.length; i++) {
    const nameCell = rows[i].cells[1];
    const stateCell = rows[i].cells[2];
    const addressCell = rows[i].cells[4];
    const name = nameCell.textContent.toLowerCase();
    const state = stateCell.textContent.toLowerCase();
    // const address = addressCell.textContent.toLowerCase();

    if (
      (selectedCriterion === "name" && name.includes(filterValue)) ||
      (selectedCriterion === "state" && state.includes(filterValue)) 
      // (selectedCriterion === "address" && address.includes(filterValue))
    ) {
      rows[i].style.display = "";
    } else {
      rows[i].style.display = "none";
    }
  }
});


function updateButtonContent() {
  const button = document.getElementById("exportBuffer");

  // Check the screen width
  if (window.innerWidth <= 768) {
    button.textContent = "Export CSV"; // Change the content for mobile screens
  } else {
    button.textContent = "Export Asset within Buffer Feature to CSV"; // Default content
  }
}

// Call the function when the page loads and on window resize
window.addEventListener("load", updateButtonContent);
window.addEventListener("resize", updateButtonContent);